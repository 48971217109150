<template>
  <div :id="'m_transverse_bar' + option.id"></div>
</template>
<script>
import Bus from "@/utils/bus";
export default {
  name: "mTransverseBar",
  directives: {},
  data() {
    return {
      myChart: null,
    };
  },
  props: {
    option: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  computed: {},
  components: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let option = {
        grid: {
          containLabel: true,
          left: "10",
          top: "10",
          right: "60",
          bottom: "0",
        },
        yAxis: [
          {
            inverse: true,
            data: this.option.yAxisData,
            axisLabel: {
              textStyle: {
                color: "#606266",
                fontSize: 12,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        xAxis: {
          //   max: 120,
          show: false,
        },
        series: [
          {
            type: "bar",
            data: this.option.seriesData,
            barWidth: 15,
            label: {
              normal: {
                show: true,
                position: "right",
                textStyle: {
                  color: "#606266",
                  fontSize: 12,
                },
              },
            },
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [this.option.color1, this.option.color2].map(
                    (color, offset) => ({
                      color,
                      offset,
                    })
                  )
                ),
              },
            },
          },
        ],
      };
      let myChart = this.$echarts.init(
        document.getElementById("m_transverse_bar" + this.option.id)
      );
      myChart.setOption(option);
      this.myChart = myChart;
      window.addEventListener(
        "resize",
        function () {
          if (myChart) {
            myChart.resize();
          }
        },
        false
      );
      Bus.$on("sizeChange", (data) => {
        if (myChart) {
          myChart.resize();
        }
      });
    },
  },
  watch: {
    option: {
      handler(data) {
        this.$nextTick((_) => {
          this.init();
        });
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less" scoped></style>
