import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 首页数据
export const reportsAggregate = data => request(`${XHR_URL}/api/v1/reports/aggregate`, data, "GET");
// 商品销售排行
export const reportsTopsalesGoods = data => request(`${XHR_URL}/api/v1/reports/topsales/goods`, data, "GET");
// 设备销售排行
export const reportsTopsalesMachine = data => request(`${XHR_URL}/api/v1/reports/topsales/machine`, data, "GET");
// 设备运营报表
export const reportsMachines = data => request(`${XHR_URL}/api/v1/reports/machines`, data, "GET");
// 设备故障报表
export const reportsFaultmachines = data => request(`${XHR_URL}/api/v1/reports/faultmachines`, data, "GET");