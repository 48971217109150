<template>
	<div class="m_home_conttent">
		<div class="m_home_conttent_row">
			<div class="m_home_conttent_col" @click="goVm">
				<div class="m_home_icon iconfont">&#xe79e;</div>
				<div class="m_home_info">
					<div>{{ aggregateData.devicesInfo.online }}</div>
					<div>在线总台数</div>
				</div>
			</div>
			<div class="m_home_conttent_col" @click="toPath('/equipment')">
				<div class="m_home_icon iconfont">&#xe634;</div>
				<div class="m_home_info">
					<div>{{ aggregateData.devicesInfo.totality }}</div>
					<div>设备总台数</div>
				</div>
			</div>
			<div class="m_home_conttent_col" @click="toPath('/equipment')">
				<div class="m_home_icon iconfont">&#xe67d;</div>
				<div class="m_home_info">
					<div>{{ aggregateData.devicesInfo.operative }}</div>
					<div>运营总台数</div>
				</div>
			</div>
			<div class="m_home_conttent_col">
				<div class="m_home_icon iconfont">&#xe666;</div>
				<div class="m_home_info">
					<div>{{ aggregateData.salesInfo.totalAmount }}</div>
					<div>总销量金额</div>
				</div>
			</div>
			<div class="m_home_conttent_col">
				<div class="m_home_icon iconfont">&#xe735;</div>
				<div class="m_home_info">
					<div>{{ aggregateData.salesInfo.totalQuantity }}</div>
					<div>总销售次数</div>
				</div>
			</div>
		</div>
		<div class="m_home_conttent_row">
			<div class="m_home_conttent_col" @click="toPath('/vmgoodSale')">
				<m-double-bar :option="option1"></m-double-bar>
			</div>
			<div class="m_home_conttent_col" @click="toPath('/vmSaleReport')">
				<m-line :option="option4"></m-line>
			</div>
			<div class="m_home_conttent_col" @click="toPath('/refund')">
				<m-double-bar :option="option2"></m-double-bar>
			</div>
			<div class="m_home_conttent_col" @click="toPath('/vmSaleReport')">
				<m-line :option="option3"></m-line>
			</div>
		</div>
		<div class="m_home_conttent_row">
			<div class="m_home_conttent_col">
				<div @click="goBH(0)">
					<div>今日补货台数</div>
					<div>{{ aggregateData.devicesInfo.todayReplenishment }}</div>
				</div>
				<div @click="goBH(1)">
					<div>昨日补货台数</div>
					<div>{{ aggregateData.devicesInfo.yesterdayReplenishment }}</div>
				</div>
			</div>

			<div class="m_home_conttent_col">
				<div @click="goStock(0)">
					<div>今日缺货台数</div>
					<div>{{ aggregateData.devicesInfo.todayOutOfStock }}</div>
				</div>
				<div @click="goStock(1)">
					<div>昨日缺货台数</div>
					<div>{{ aggregateData.devicesInfo.yesterdayOutOfStock }}</div>
				</div>
			</div>
			<div class="m_home_conttent_col">
				<div @click="goFault(0)">
					<div>今日故障台数</div>
					<div>{{ aggregateData.devicesInfo.todayFault }}</div>
				</div>
				<div @click="goFault(1)">
					<div>昨日故障台数</div>
					<div>{{ aggregateData.devicesInfo.yesterdayFault }}</div>
				</div>
			</div>
		</div>
		<div class="m_home_conttent_row">
			<div class="m_home_conttent_col">
				<div class="m_home_title">商品总销售量排行</div>
				<div class="m_home_body">
					<m-transverse-bar :option="option5"></m-transverse-bar>
				</div>
			</div>
			<div class="m_home_conttent_col">
				<div class="m_home_title">设备总销量排行</div>
				<div class="m_home_body">
					<m-transverse-bar :option="option6"></m-transverse-bar>
				</div>
			</div>
			<div class="m_home_conttent_col">
				<div class="m_home_title">商品今日销售量排行</div>
				<div class="m_home_body">
					<m-transverse-bar :option="option7"></m-transverse-bar>
				</div>
			</div>
			<div class="m_home_conttent_col">
				<div class="m_home_title">设备今日销量排行</div>
				<div class="m_home_body">
					<m-transverse-bar :option="option8"></m-transverse-bar>
				</div>
			</div>
		</div>
		<div class="m_home_conttent_row">
			<el-cascader :options="treeData" :show-all-levels="false" :props="cascaderProps" v-model="areaData" placeholder="请选择行政区域" clearable class='inputs'></el-cascader>
			<el-button @click="saleSort" class="m_sort m_btn_common">销量排序</el-button>
			<el-button @click="stockSort" class="m_sort m_btn_common">库存排序</el-button>
			<el-button @click="faultSort" class="m_sort m_btn_common">故障排序</el-button>
    
		</div>
		<div class="m_home_conttent_row">
			<div class="m_home_conttent_col" v-for="(item, i) in reportsMachinesData" :key="i">
				<div class="m_home_baseinfo">
					<div class="m_home_baseinfo_left">
						<img :src="fileUrl + item.logo" alt="" />
						<div>{{ item.code }}</div>
					</div>
					<div class="m_home_baseinfo_right">
						<div class="m_cont_hide" :title="item.franchiName">
							<div>合伙人：</div>
							<div>{{ item.franchiName }}</div>
						</div>
						<div class="m_cont_hide" :title="item.operatorName">
							<div>加盟商：</div>
							<div>{{ item.operatorName }}</div>
						</div>
						<div class="m_cont_hide" :title="item.operatorName">
							<div>维护电话：</div>
							<div>{{ item.adminMobile }}</div>
						</div>
						
						<div class="m_cont_hide" :title="item.code">
							<div>设备编号：</div>
							<div>{{ item.code }}</div>
						</div>
						<div class="m_cont_hide" :title="item.name">
							<div>设备名称：</div>
							<div>{{ item.name }}</div>
						</div>
						<div>
							<div>状态：</div>
							<div>
								<p v-if="item.faultStatus === 'Invalid'">
									<i style="color: #ff4343">故障</i>(
									<i v-if="item.machStatus === 'Online'" style="color: #62c462">在线</i>
									<i v-else style="color: #ff4343">离线</i> )
								</p>
								<p v-else>
									<i style="color: #62c462">正常</i>(
									<i v-if="item.machStatus === 'Online'" style="color: #62c462">在线</i>
									<i v-else style="color: #ff4343">离线</i> )
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="m_home_countinfo">
					<div class="m_home_countinfo_row">
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe666;</span>
								<span>今日销售金额</span>
							</div>
							<div>{{ item.todaySaleAmount }}</div>
						</div>
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe735;</span>
								<span>今日销售次数</span>
							</div>
							<div>{{ item.todaySaleQuantity }}</div>
						</div>
					</div>
					<div class="m_home_countinfo_row">
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe607;</span>
								<span>总库存数</span>
							</div>
							<div>{{ item.totalStockQuantity }}</div>
						</div>
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe693;</span>
								<span>空货道数</span>
							</div>
							<div>{{ item.emptyCargoTrackQuantity }}</div>
						</div>
					</div>
					<div class="m_home_countinfo_row">
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe69e;</span>
								<span>退款金额</span>
							</div>
							<div>{{ item.refundAmount }}</div>
						</div>
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe629;</span>
								<span>退款次数</span>
							</div>
							<div>{{ item.refundQuantity }}</div>
						</div>
					</div>
					<div class="m_home_countinfo_row">
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe65d;</span>
								<span>补货次数</span>
							</div>
							<div>{{ item.replenishmentQuantity }}</div>
						</div>
						<div class="m_home_countinfo_col">
							<div>
								<span class="iconfont">&#xe60a;</span>
								<span>故障次数</span>
							</div>
							<div>{{ item.faults }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="m_home_page">
				<m-pagination @pageChange="pageVmChange" :total="vmTotal"></m-pagination>
		</div>
		<div class="m_home_conttent_row">
			<div class="m_home_table">
				<el-table :data="tableData" stripe>
					<el-table-column label="序号" type="index" width="100" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="machineCode" label="故障设备编号" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="franchiName" label="合伙人" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="operatorName" label="加盟商" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="locationName" label="点位地址" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="admin" label="运维人员" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.adminName }}：{{scope.row.adminMobile}}
						</template>
					</el-table-column>
					<el-table-column prop="onFaultTime" label="故障时间" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="note" label="故障描述" show-overflow-tooltip>
					</el-table-column>
				</el-table>
			</div>
			<div class="m_home_page">
				<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE, XHR_URL } from "@/utils/config";
	import mDoubleBar from "@/components/chart/doublebar.vue";
	import mLine from "@/components/chart/line.vue";
	import mTransverseBar from "@/components/chart/transversebar.vue";
	import {
		reportsAggregate,
		reportsTopsalesGoods,
		reportsTopsalesMachine,
		reportsMachines,
		reportsFaultmachines,
	} from "@/api/home";
	import { regions } from "@/api/base";
	export default {
		directives: {},
		data() {
			const _this = this;
			return {
				total: 0,
				currentPage: 1,
				tableData: [],
				aggregateData: {
					devicesInfo: {},
					salesInfo: {},
				},
				reportsMachinesData: [],
				option1: {
					id: 1,
					legendData: ["销售金额", "退款金额"],
					yAxisName: "元",
					series: [
						[0, 0],
						[0, 0],
					],
				},
				option2: {
					id: 2,
					legendData: ["销售次数", "退款次数"],
					yAxisName: "次",
					series: [
						[0, 0],
						[0, 0],
					],
				},
				option3: {
					id: 1,
					legendData: ["销售次数"],
					yAxisName: "次",
					seriesData: [0, 0],
					color1: "rgba(64,178,126,1)",
					color2: "rgba(64,178,126,0.1)",
				},
				option4: {
					id: 2,
					legendData: ["销售金额"],
					yAxisName: "元",
					seriesData: [0, 0],
					color1: "rgba(161,85,247,1)",
					color2: "rgba(161,85,247,0.1)",
				},
				option5: {
					id: 1,
					yAxisData: [],
					seriesData: [],
					color1: "#F8B06C",
					color2: "#F8773C",
				},
				option6: {
					id: 2,
					yAxisData: [],
					seriesData: [],
					color1: "#5DD7E9",
					color2: "#3FADE5",
				},
				option7: {
					id: 3,
					yAxisData: [],
					seriesData: [],
					color1: "#916AF7",
					color2: "#A255F7",
				},
				option8: {
					id: 4,
					yAxisData: [],
					seriesData: [],
					color1: "#76DA91",
					color2: "#3FB27E",
				},
				currVmPage:1,
				vmTotal:0,
				sortText:'total',
				areaData:[],
				treeData: [],
				cascaderProps: {
					lazy: true,
					checkStrictly: true,
					async lazyLoad(node, resolve) {
						const result = await _this.lazyLoad(node);
						resolve(result);
					},
				},
				timer:null
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl() {
				return XHR_URL;
			},
		},
		components: {
			mPagination,
			mDoubleBar,
			mLine,
			mTransverseBar,
		},
		created() {
			this.homeLoad();
		},
		mounted() {},
		methods: {
			async homeLoad(){
				this.reportsAggregate();
				this.reportsTopsalesGoodsTotal();
				this.reportsTopsalesMachineTotal();
				this.reportsTopsalesGoodsToday();
				this.reportsTopsalesMachineToday();
				this.reportsMachinesTotal();
				this.reportsFaultmachines();
			    const res = await this.regions();
			    this.treeData = res;
			    if(this.timer==null){
					console.log('进入1')
	  				this.timer=setInterval(this.homeLoad,10000);
				}
			},
			// 分页
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			pageVmChange(page) {
				this.currVmPage = page;
				this.reportsMachinesTotal();
			},
			saleSort(){
				this.sortText='total';
				this.reportsMachinesTotal();
			},
			stockSort(){
				this.sortText='stock';
				this.reportsMachinesTotal();
			},
			faultSort(){
				this.sortText='fault';
				this.reportsMachinesTotal();
			},
			// 跳转
			toPath(path) {
				this.$router.push(path);
			},
			// 获取首页数据
			async reportsAggregate() {
				const res = await reportsAggregate();
				const {
					pageCount,
					results
				} = res;
				this.aggregateData = results[0];
				this.option1 = {
					id: 1,
					legendData: ["销售金额", "退款金额"],
					yAxisName: "元",
					series: [
						[
							this.aggregateData.salesInfo.yesterdayAmount,
							this.aggregateData.salesInfo.todayAmount,
						],
						[
							this.aggregateData.salesInfo.yesterdayRefundAmount,
							this.aggregateData.salesInfo.todayRefundAmount,
						],
					],
				};
				this.option4 = {
					id: 2,
					legendData: ["销售金额"],
					yAxisName: "元",
					seriesData: [
						this.aggregateData.salesInfo.firstMonthAmount,
						this.aggregateData.salesInfo.thisMonthAmount,
					],
					color1: "rgba(161,85,247,1)",
					color2: "rgba(161,85,247,0.1)",
				};
				this.option2 = {
					id: 2,
					legendData: ["销售次数", "退款次数"],
					yAxisName: "次",
					series: [
						[
							this.aggregateData.salesInfo.yesterdayQuantity,
							this.aggregateData.salesInfo.todayQuantity,
						],
						[
							this.aggregateData.salesInfo.yesterdayRefundQuantity,
							this.aggregateData.salesInfo.todayRefundQuantity,
						],
					],
				};
				this.option3 = {
					id: 1,
					legendData: ["销售次数"],
					yAxisName: "次",
					seriesData: [
						this.aggregateData.salesInfo.firstMonthQuantity,
						this.aggregateData.salesInfo.thisMonthQuantity,
					],
					color1: "rgba(64,178,126,1)",
					color2: "rgba(64,178,126,0.1)",
				};
			},
			// 获取商品总销量
			async reportsTopsalesGoodsTotal() {
				const res = await reportsTopsalesGoods({
					top: 5,
					type: "total",
				});
				const {
					pageCount,
					results
				} = res;
				const yAxisData = [];
				const seriesData = [];
				results.forEach((item) => {
					yAxisData.push(item.name);
					seriesData.push(item.saleqty);
				});
				this.option5 = {
					id: 1,
					yAxisData: yAxisData,
					seriesData: seriesData,
					color1: "#F8B06C",
					color2: "#F8773C",
				};
			},
			// 获取设备总销量
			async reportsTopsalesMachineTotal() {
				const res = await reportsTopsalesMachine({
					top: 5,
					type: "total",
				});
				const {
					pageCount,
					results
				} = res;
				const yAxisData = [];
				const seriesData = [];
				results.forEach((item) => {
					yAxisData.push(item.name);
					seriesData.push(item.saleqty);
				});
				this.option6 = {
					id: 2,
					yAxisData: yAxisData,
					seriesData: seriesData,
					color1: "#5DD7E9",
					color2: "#3FADE5",
				};
			},
			// 获取商品今日销量
			async reportsTopsalesGoodsToday() {
				const res = await reportsTopsalesGoods({
					top: 5,
					type: "today",
				});
				const {
					pageCount,
					results
				} = res;
				const yAxisData = [];
				const seriesData = [];
				results.forEach((item) => {
					yAxisData.push(item.name);
					seriesData.push(item.saleqty);
				});
				this.option7 = {
					id: 3,
					yAxisData: yAxisData,
					seriesData: seriesData,
					color1: "#916AF7",
					color2: "#A255F7",
				};
			},
			// 获取设备今日销量
			async reportsTopsalesMachineToday() {
				const res = await reportsTopsalesMachine({
					top: 5,
					type: "today",
				});
				const {
					pageCount,
					results
				} = res;
				const yAxisData = [];
				const seriesData = [];
				results.forEach((item) => {
					yAxisData.push(item.name);
					seriesData.push(item.saleqty);
				});
				this.option8 = {
					id: 4,
					yAxisData: yAxisData,
					seriesData: seriesData,
					color1: "#76DA91",
					color2: "#3FB27E",
				};
			},
			// 获取设备运营报表
			async reportsMachinesTotal() {
				const res = await reportsMachines({
					top: 5,
					type: this.sortText,
					pageNo: this.currVmPage,
					pageSize: this.pageSize,
					province: this.areaData && this.areaData[0] ? this.areaData[0] : null,
					city: this.areaData && this.areaData[1] ? this.areaData[1] : null,
					county: this.areaData && this.areaData[2] ? this.areaData[2] : null,
				});
				const {
					pageCount,
					results
				} = res;
				this.reportsMachinesData = results;
				this.vmTotal = pageCount.allCount;
			},
			// 获取设备故障报表
			async reportsFaultmachines() {
				const res = await reportsFaultmachines({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async lazyLoad(node) {
				const {
					level
				} = node;
				let query = null;
				switch(level) {
					case 1:
						query = {
							province: node.path[0],
						};
						break;
					case 2:
						query = {
							province: node.path[0],
							city: node.path[1],
						};
						break;
				}
				if(!query) return;
				const res = await this.regions(query, level);
				return res;
			},
			async regions(data, level) {
				const res = await regions(data);
				const {
					pageCount,
					results
				} = res;
				let list = [];
				results.forEach((item) => {
					list.push({
						value: item,
						label: item,
						leaf: level === 2,
					});
				});
				return list;
			},
			goVm(){
				this.$router.push({
						path:'/equipment',
						query:{
							machstatus:'Online',
						}
					})
			},
			goBH(ind){
				let date =  this.$options.filters.dateformat(new Date());
				if(ind ==0){
					this.$router.push({
						path:'/replenish',
						query:{
							beginDate:date+' 00:00:00',
							endDate:date+' 23:59:59'
						}
					})
				}else{
					let date = this.$options.filters.dateformat(new Date().getTime()-86400000);
					this.$router.push({
						path:'/replenish',
						query:{
							beginDate:date+' 00:00:00',
							endDate:date+' 23:59:59'
						}
					})
				}
			},
			goStock(ind){
				let date =  this.$options.filters.dateformat(new Date());
				if(ind ==0){
					this.$router.push({
						path:'/stock',
						query:{
							beginDate:date+' 00:00:00',
							endDate:date+' 23:59:59'
						}
					})
				}else{
					let date = this.$options.filters.dateformat(new Date().getTime()-86400000);
					this.$router.push({
						path:'/stock',
						query:{
							beginDate:date+' 00:00:00',
							endDate:date+' 23:59:59'
						}
					})
				}
			},
			goFault(ind){
				let date =  this.$options.filters.dateformat(new Date());
				if(ind ==0){
					this.$router.push({
						path:'/vmfail',
						query:{
							beginDate:date+' 00:00:00',
							endDate:date+' 23:59:59'
						}
					})
				}else{
					let date = this.$options.filters.dateformat(new Date().getTime()-86400000);
					this.$router.push({
						path:'/vmfail',
						query:{
							beginDate:date+' 00:00:00',
							endDate:date+' 23:59:59'
						}
					})
				}
			}
		},
		beforeDestroy() {
			if(this.timer){
		  		console.log('清除刷新');
		  		clearInterval(this.timer);
		  	}
		},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/home/home.less";
</style>