<template>
  <div :id="'m_double_bar_' + option.id"></div>
</template>
<script>
import Bus from "@/utils/bus";
export default {
  name: "mDoubleBar",
  directives: {},
  data() {
    return {
      myChart: null,
    };
  },
  props: {
    option: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  computed: {},
  components: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },
        grid: {
          containLabel: true,
          left: "10",
          top: "40",
          right: "10",
          bottom: "10",
        },
        legend: {
          data: this.option.legendData,
          top: "4%",
          textStyle: {
            color: "#606266",
            fontSize: 12,
          },
        },
        xAxis: {
          data: ["昨日", "今日"],
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#606266",
              width: 1,
            },
          },
          axisTick: {
            show: true, //隐藏X轴刻度
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#606266", //X轴文字颜色
              fontSize: 12,
            },
            interval: 0,
          },
        },
        yAxis: [
          {
            type: "value",
            name: this.option.yAxisName,
            nameTextStyle: {
              color: "#606266",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: "#606266",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#606266",
                fontSize: 12,
              },
            },
          },
        ],
        series: [
          {
            name: this.option.legendData[0],
            type: "bar",
            barWidth: 18,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#72B3FF",
                  },
                  {
                    offset: 1,
                    color: "#4978FE",
                  },
                ]),
              },
            },
            data: this.option.series[0],
          },
          {
            name: this.option.legendData[1],
            type: "bar",
            barWidth: 18,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#F8B06C",
                  },
                  {
                    offset: 1,
                    color: "#F8773C",
                  },
                ]),
              },
            },
            data: this.option.series[1],
          },
        ],
      };
      let myChart = this.$echarts.init(
        document.getElementById("m_double_bar_" + this.option.id)
      );
      myChart.setOption(option);
      this.myChart = myChart;
      window.addEventListener(
        "resize",
        function () {
          if (myChart) {
            myChart.resize();
          }
        },
        false
      );
      Bus.$on("sizeChange", (data) => {
        if (myChart) {
          myChart.resize();
        }
      });
    },
  },
  watch: {
    option: {
      handler(data) {
        this.$nextTick((_) => {
          this.init();
        });
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less" scoped></style>
